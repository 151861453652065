*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --color-primary: #00966c;
  --color-primary-dark: #00291e;
  --color-primary-neon: #00e6a5;
  --color-primary-light: #eaf5e8;
  --color-sunny-green: #00d797;
  --color-regal-blue: #00a2b8;
  --color-deep-dark: #d76700;
  --color-deep-bright: #9e00b8;
  --color-dark: #212121;
  --color-white: #fff;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-top-1 {
  top: -.25rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-\[4rem\] {
  bottom: 4rem;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.\!my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-8 {
  height: 2rem;
}

.h-\[20rem\] {
  height: 20rem;
}

.h-\[65px\] {
  height: 65px;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[60rem\] {
  max-height: 60rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-40 {
  width: 10rem;
}

.w-60 {
  width: 15rem;
}

.w-8 {
  width: 2rem;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-md {
  max-width: 28rem;
}

.grow {
  flex-grow: 1;
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.text-nowrap {
  text-wrap: nowrap;
}

.text-balance {
  text-wrap: balance;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.\!border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.border-b-dark\/5 {
  border-bottom-color: #2121210d;
}

.\!bg-red-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 150 108 / var(--tw-bg-opacity));
}

.bg-primary-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(0 41 30 / var(--tw-bg-opacity));
}

.bg-primary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(234 245 232 / var(--tw-bg-opacity));
}

.bg-primary-light\/80 {
  background-color: #eaf5e8cc;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[linear-gradient\(to_bottom\,\#00291eDA\,\#00291eDA\)\,url\(\'\.\.\/img\/DJI_0461\.jpg\'\)\] {
  background-image: linear-gradient(#00291eda, #00291eda), url("DJI_0461.e48f1e97.jpg");
}

.bg-\[linear-gradient\(to_bottom\,\#00291eDA\,\#00291eDA\)\,url\(\'\.\.\/img\/DSC_0449-HDR\.jpg\'\)\] {
  background-image: linear-gradient(#00291eda, #00291eda), url("DSC_0449-HDR.87726457.jpg");
}

.bg-\[linear-gradient\(to_bottom\,\#00291eDA\,\#00291eDA\)\,url\(\'\.\.\/img\/DSC_0467-HDR\.jpg\'\)\] {
  background-image: linear-gradient(#00291eda, #00291eda), url("DSC_0467-HDR.9bb15a06.jpg");
}

.bg-\[linear-gradient\(to_bottom\,\#00291eDA\,\#00291eDA\)\,url\(\'\.\.\/img\/cup-with-badge\.png\'\)\] {
  background-image: linear-gradient(#00291eda, #00291eda), url("cup-with-badge.3ca04c94.png");
}

.bg-\[linear-gradient\(to_bottom\,\#00966cDD\,\#00966cDD\)\,url\(\'https\:\/\/images\.unsplash\.com\/photo-1558591710-4b4a1ae0f04d\?q\=80\&w\=2574\&auto\=format\&fit\=crop\&ixlib\=rb-4\.0\.3\&ixid\=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA\%3D\%3D\'\)\] {
  background-image: linear-gradient(#00966cdd, #00966cdd), url("https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
}

.bg-\[url\(\'\.\.\/img\/reviews\/1\.png\'\)\] {
  background-image: url("1.11310555.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/10\.png\'\)\] {
  background-image: url("10.b10b3e49.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/2\.png\'\)\] {
  background-image: url("2.e69a76a1.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/3\.png\'\)\] {
  background-image: url("3.6db4488b.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/4\.png\'\)\] {
  background-image: url("4.7cbbb788.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/5\.png\'\)\] {
  background-image: url("5.3d3b4ca4.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/6\.png\'\)\] {
  background-image: url("6.8eab8374.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/7\.png\'\)\] {
  background-image: url("7.c2403939.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/8\.png\'\)\] {
  background-image: url("8.2213eada.png");
}

.bg-\[url\(\'\.\.\/img\/reviews\/9\.png\'\)\] {
  background-image: url("9.1ac423d3.png");
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-primary {
  --tw-gradient-from: #00966c var(--tw-gradient-from-position);
  --tw-gradient-to: #00966c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-primary {
  --tw-gradient-to: #00966c var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.p-8 {
  padding: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.font-body {
  font-family: Inter, arial, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-medium {
  font-weight: 500;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.\!text-primary-dark {
  --tw-text-opacity: 1 !important;
  color: rgb(0 41 30 / var(--tw-text-opacity)) !important;
}

.\!text-primary-light {
  --tw-text-opacity: 1 !important;
  color: rgb(234 245 232 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 150 108 / var(--tw-text-opacity));
}

.text-primary-dark {
  --tw-text-opacity: 1;
  color: rgb(0 41 30 / var(--tw-text-opacity));
}

.text-primary-light {
  --tw-text-opacity: 1;
  color: rgb(234 245 232 / var(--tw-text-opacity));
}

.text-primary-light\/60 {
  color: #eaf5e899;
}

.text-primary-neon\/90 {
  color: #00e6a5e6;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-45 {
  opacity: .45;
}

.opacity-50 {
  opacity: .5;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-2 {
  outline-width: 2px;
}

.outline-dark\/20 {
  outline-color: #21212133;
}

.outline-primary\/20 {
  outline-color: #00966c33;
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.full-bleed {
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  left: 50%;
  right: 50%;
}

.btn.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.btn.btn-md {
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-wrap: nowrap;
  --tw-text-opacity: 1;
  color: rgb(0 41 30 / var(--tw-text-opacity));
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.btn:hover {
  text-decoration-line: underline;
}

.btn.btn-ghost:hover {
  background-color: #00966c33;
  text-decoration-line: none;
}

.btn.btn-outline {
  --tw-border-opacity: 1;
  border-color: rgb(0 150 108 / var(--tw-border-opacity));
}

.btn.btn-outline:hover {
  background-color: #00966c33;
  text-decoration-line: none;
}

.btn.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 150 108 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn.btn-primary:hover {
  background-color: #00966ce6;
  text-decoration-line: none;
}

.section-pre-heading {
  --tw-text-opacity: 1;
  color: rgb(33 33 33 / var(--tw-text-opacity));
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Inter, arial, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.section-big-bold-heading {
  text-wrap: balance;
  letter-spacing: -.05em;
  background-attachment: fixed;
  font-family: Bricolage Grotesque, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 3rem;
  font-weight: 900;
  line-height: 1;
}

@media not all and (width >= 768px) {
  .section-big-bold-heading {
    line-height: 3.5rem;
  }
}

.section-big-bold-heading.heading-gradient-1 {
  --tw-text-opacity: 1;
  color: rgb(0 162 184 / var(--tw-text-opacity));
}

.section-big-bold-heading.heading-gradient-2 {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #d76700 var(--tw-gradient-from-position);
  --tw-gradient-to: #d7670000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #9e00b8 var(--tw-gradient-to-position);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.section-body {
  text-wrap: balance;
  --tw-text-opacity: 1;
  color: rgb(33 33 33 / var(--tw-text-opacity));
}

.underline--magical {
  background-image: linear-gradient(120deg, var(--color-sunny-green) 0%, var(--color-regal-blue) 100%);
  background-position: 0 110%;
  background-repeat: no-repeat;
  background-size: 100% .2em;
  transition: background-size .25s ease-in;
}

.underline--magical.gradient-2 {
  background-image: linear-gradient(120deg, var(--color-deep-dark) 0%, var(--color-deep-bright) 100%);
}

.review-images-pagination-page.is-active {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(105 65 198 / var(--tw-bg-opacity)) !important;
  opacity: 1 !important;
}

.review-images-pagination {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
  position: relative !important;
  bottom: 0 !important;
}

.hover\:-translate-y-0:hover {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:-translate-y-0\.5:hover {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-primary-dark\/90:hover {
  background-color: #00291ee6;
}

.hover\:bg-primary-light\/5:hover {
  background-color: #eaf5e80d;
}

.hover\:bg-primary-light\/90:hover {
  background-color: #eaf5e8e6;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:underline:active {
  text-decoration-line: underline;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

@media not all and (width >= 1280px) {
  .max-xl\:w-full {
    width: 100%;
  }

  .max-xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media not all and (width >= 1024px) {
  .max-lg\:m-0 {
    margin: 0;
  }

  .max-lg\:max-w-full {
    max-width: 100%;
  }
}

@media not all and (width >= 768px) {
  .max-md\:m-0 {
    margin: 0;
  }

  .max-md\:mt-2 {
    margin-top: .5rem;
  }

  .max-md\:hidden {
    display: none;
  }

  .max-md\:min-h-72 {
    min-height: 18rem;
  }

  .max-md\:w-full {
    width: 100%;
  }

  .max-md\:max-w-full {
    max-width: 100%;
  }

  .max-md\:flex-col {
    flex-direction: column;
  }

  .max-md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .max-md\:flex-wrap {
    flex-wrap: wrap;
  }

  .max-md\:justify-center {
    justify-content: center;
  }

  .max-md\:overflow-x-auto {
    overflow-x: auto;
  }

  .max-md\:border-t {
    border-top-width: 1px;
  }

  .max-md\:border-t-primary\/10 {
    border-top-color: #00966c1a;
  }

  .max-md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .max-md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .max-md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .max-md\:pb-0 {
    padding-bottom: 0;
  }

  .max-md\:text-start {
    text-align: start;
  }
}

@media not all and (width >= 640px) {
  .max-sm\:justify-start {
    justify-content: flex-start;
  }
}

/*# sourceMappingURL=index.8ddaa2a1.css.map */
