@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #00966c;
    --color-primary-dark: #00291e;
    --color-primary-neon: #00e6a5;
    --color-primary-light: #eaf5e8;
    --color-sunny-green: #00d797;
    --color-regal-blue: #00a2b8;
    --color-deep-dark: #d76700;
    --color-deep-bright: #9e00b8;
    --color-dark: #212121;
    --color-white: #ffffff;
  }
}

@layer utilities {
  .full-bleed {
    @apply w-screen left-1/2 right-1/2 mx-[-50vw] relative;
  }

  .menu-item {
    @apply border border-transparent hover:border-primary-dark/20 rounded-full px-4 py-1 hover:bg-primary-dark/10;
  }

  .btn.btn-sm {
    @apply px-2 py-1 text-sm;
  }

  .btn.btn-md {
    @apply px-4 py-2 text-base;
  }

  .btn.btn-lg {
    @apply px-4 py-2 text-lg;
  }

  .btn {
    @apply font-medium px-4 py-2 text-base rounded-md border border-transparent hover:underline text-primary-dark text-nowrap cursor-pointer select-none;
  }

  .btn.btn-ghost {
    @apply hover:bg-primary/20 hover:no-underline;
  }

  .btn.btn-outline {
    @apply hover:bg-primary/20 border-primary hover:no-underline;
  }

  .btn.btn-primary {
    @apply bg-primary hover:bg-primary/90 text-white hover:no-underline;
  }

  .section-pre-heading {
    @apply text-lg font-info text-dark my-4 font-semibold;
  }

  .section-big-bold-heading {
    @apply font-heading text-5xl font-black tracking-tighter bg-fixed text-balance max-md:leading-[3.5rem];
  }

  .section-big-bold-heading.heading-gradient-1 {
    @apply text-regal-blue;
  }

  .section-big-bold-heading.heading-gradient-2 {
    @apply bg-clip-text text-transparent bg-gradient-to-b from-deep-dark to-deep-bright;
  }

  .section-body {
    @apply text-balance text-dark;
  }

  .underline--magical {
    background-image: linear-gradient(
      120deg,
      var(--color-sunny-green) 0%,
      var(--color-regal-blue) 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    /* background-size: 100% 88%; */
    background-position: 0 110%;
    transition: background-size 0.25s ease-in;
  }

  .underline--magical.gradient-2 {
    background-image: linear-gradient(
      120deg,
      var(--color-deep-dark) 0%,
      var(--color-deep-bright) 100%
    );
  }

  .bg-lf-gray-lighter {
    background: transparent !important;
  }

  .review-images-pagination-page.is-active {
    @apply !opacity-100 !bg-[#6941C6];
  }

  .review-images-pagination {
    @apply !relative !bottom-0 !my-2;
  }
}
